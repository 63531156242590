// bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root,
body,
main {
  max-width: 100vw;
  overflow-x: hidden;
}

$br: 25px;

$primary: rgb(65, 95, 235);
$light: rgb(220, 220, 235);

.color-primary {
  color: $primary;
}

.h100 {
  height: 100%;
}

.text-center {
  text-align: center;
}

p {
  letter-spacing: 1px;
}

.fs-5 {
  font-weight: 300;
}

button {
  background-color: $primary;
  color: white;
  border-radius: $br;
  padding: 5px 15px;
  border: unset;
}

.download-modal-container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;

  .download-modal-content {
    background-color: white;
    border-radius: $br;
    padding: 25px;

    width: 100%;
    max-width: 600px;

    display: flex;
    align-items: center;
    justify-content: center;

    .paper {
      width: 50px;
    }

    form {
      width: 100%;

      input {
        width: 100%;
        border-radius: $br;
        padding: 5px 15px;
        border: 1px solid $primary;
      }
    }
  }
}

header {
  width: 100%;
  position: fixed;

  top: 0;
  left: 0;

  z-index: 99;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  background-color: white;
  height: 25px;

  .btn-container {
    background-color: white;
    border-radius: $br;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    a {
      font-size: larger;
      @media only screen and (max-width: 900px) {
        font-size: large;
      }
      font-weight: bolder;
      color: $primary;

      margin: 30px;
      line-height: 20px;

      transform: translateY(15px);

      text-decoration: none;

      text-align: right;
    }
  }
}

#home {
  margin-top: 30px;
  .hero {
    position: relative;

    border: 25px solid white;

    .hero-img {
      border-radius: $br;
      width: 100%;
      height: 90vh;

      object-fit: cover;
    }

    .hero-logo {
      position: absolute;

      max-width: 800px;
      min-width: 250px;
      top: 0;
      left: 0;
      width: 50vw;

      background-color: white;
      padding: 25px;
      border-bottom-right-radius: $br;
    }

    hgroup {
      position: absolute;
      top: 40vh;
      width: 100%;
      text-align: center;

      color: white;

      h1 {
        line-height: 80px;
        font-size: 100px;
        margin-bottom: 15px;
      }

      h2 {
        font-weight: light;
      }
    }
  }

  .world {
    width: 100%;
    max-width: 400px;
    border-radius: $br;
  }

  .connect {
    position: relative;

    .text {
      position: absolute;
      top: 40px;

      color: white;
      // text-align: center;
      // background-color: black;
      border-radius: $br;
      padding: 25px;
      margin: 25px;
    }

    .connect-image-container {
      position: relative;

      .connect-image {
        width: 100%;
        height: 600px;
        object-fit: cover;
        border-radius: $br;

        filter: brightness(0.2);

        @media only screen and (max-width: 600px) {
          height: 700px;
        }
        @media only screen and (max-width: 900px) {
          height: 1000px;
        }
      }

      .image-mask {
        background-color: white;
        border-radius: $br;
        z-index: 2;

        &:nth-of-type(1) {
          position: absolute;
          height: 100px;
          width: 200px;
          bottom: -50px;
          left: -50px;

          @media only screen and (max-width: 900px) {
            display: none;
          }
        }

        &:nth-of-type(2) {
          position: absolute;
          height: 200px;
          width: 200px;
          bottom: 0px;
          left: -100px;

          @media only screen and (max-width: 900px) {
            display: none;
          }
        }

        &:nth-of-type(3) {
          position: absolute;
          height: 100px;
          width: 200px;
          top: -70px;
          right: -100px;
        }
      }
    }
  }

  .web3 {
    background-color: $primary;
    color: white;
    margin: 25px;
    border-radius: $br;
    padding: 25px 0;
  }
}

footer {
  width: 100vw;
  background-color: $light;
  border-top-left-radius: $br;
  border-top-right-radius: $br;

  .footer-logo {
    height: 100px;
    transform: translateX(-119px);
  }
}
